import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import PanoptoLogoImageUrl from "../Assets/Images/panopto-logo-black-text.svg";

const LogoImage = styled("img")(({theme}) => ({
    maxWidth: 250,
    minWidth: 250,
    width: "50%",
    marginTop: 32,
    [theme.breakpoints.down("md")]: {
        minWidth: 180,
        maxWidth: 240,
        width: "35%",
        marginTop: 0,
    },
}));

const Banner = (): JSX.Element => (
    <Box
        display="flex"
        role="banner"
        justifyContent="center"
        alignItems="center"
    >
        <LogoImage
            src={PanoptoLogoImageUrl}
            alt="Panopto"
        />
    </Box>
);

export default Banner;
