import React from "react";
import
{
    Grid, Link, Typography, 
} from "@mui/material";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import { Utils } from "../Interfaces/Utils";
import GridWithLimitedHeight from "./Shared/GridWithLimitedHeight";
import { Trans, withTranslation } from "react-i18next";
import EnterpriseSection from "./PanoptoEnterprise/EnterpriseSection";
import ProSection from "./ProSection";
import SectionsDivider from "./SectionsDivider";
import { FindMyPanoptoDefaultProps } from "../Interfaces/FindMyPanoptoDefaultProps";

/**
 * Account picker props
 * */
type AccountPickerProps = FindMyPanoptoDefaultProps

interface AccountPickerState
{
    isPrivateSectionVisible: boolean,

    isEnterpriseSectionVisible: boolean,
    isEnterpriseSiteListVisible: boolean,
    isEnterpriseChooseButtonVisible: boolean,
}
/**
 * Account chooser component
 * @param props
 * @returns
 */
class AccountPicker extends React.Component<AccountPickerProps, AccountPickerState> 
{
    private readonly utils: Utils;
    constructor (props: AccountPickerProps)
    {
        super(props);

        this.state = {
            isEnterpriseSectionVisible: true,
            isEnterpriseChooseButtonVisible: true,
            isEnterpriseSiteListVisible: false,
            isPrivateSectionVisible: true,
        };

        this.utils = props.utils;
    }

    /**
     * Back to search form
     */
    private onReenterClick = (): void =>
    {
        const { resetState } = this.context;
        resetState();
    }
    
    render = (): JSX.Element =>
    {
        return (
            <Grid
                container
                spacing={3}
                direction="row"
            >
                <Grid
                    item
                    xs={12}
                >
                    <GridWithLimitedHeight
                        container
                    >
                        <EnterpriseSection siteHelpers={this.utils.siteHelpers} />
                        <SectionsDivider />
                        <ProSection siteHelpers={this.utils.siteHelpers}/>
                    </GridWithLimitedHeight>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Typography variant="body1">
                                <Trans>Wrong sites?</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link
                                sx={{
                                    verticalAlign: "baseline"
                                }}
                                underline="none"
                                variant="body1"
                                component="button"
                                onClick={this.onReenterClick}
                            >
                                <Trans>Re-enter your email address</Trans>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
}

AccountPicker.contextType = FindMyPanoptoContext;
export default withTranslation()(AccountPicker);