import React, { useContext } from "react";
import
{
    List, ListItem, ListItemAvatar, Avatar, ListItemText, styled, Box
} from "@mui/material";
import WizardStep from "../../Models/WizardStep";
import Site from "../../Models/Site";
import { SiteHelpers } from "../../Utils/SiteHelpers";
import FindMyPanoptoContext from "../../Context/FindMyPanoptoContext";

const ListStyled = styled(List)({
    width: "90%",
    paddingRight: 0,
    overflowY: "auto"
});

/**
 * Gradient box used at the bottom of the list to indicate 
 * more content in the list (>3) 
 */
const Gradient = styled(Box)(({
    position: "absolute",
    display: "none",
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    zIndex: 10,
    height: 64,
    background: "linear-gradient(to bottom,rgba(255, 255, 255, 0.0),#FFFFFF)"
}));

/**
 * EnterpriseSection props
 */
interface EnterpriseSitesListProps
{
    siteHelpers: SiteHelpers,
    isSmallScreen: boolean
}

const EnterpriseSitesList: React.FC<EnterpriseSitesListProps> = (props: EnterpriseSitesListProps): JSX.Element =>
{
    const context = useContext(FindMyPanoptoContext);
    const {
        allSitesList, wizardStep, destination, redirectParams
    } = context;

    if (wizardStep !== WizardStep.ChoosingSite)
    {
        return <></>;
    }

    const { siteHelpers, isSmallScreen } = props;

    /**
     * On Enterprise site clicked
     */
    const onEnterpriseListItemClicked = (targetSite: Site) =>
    {
        window.location.href = siteHelpers.buildRedirectUrl(targetSite, destination, redirectParams);
    };

    /**
     * Get Site list based on dimension 
     * @returns Site List
     */
    const getSiteList = (): Site[] =>
    {
        if (isSmallScreen)
        {
            return allSitesList?.sites || [];
        }

        return (allSitesList?.sites || []).filter((site: Site) => !siteHelpers.isPersonal(site));
    };
    const siteList = getSiteList();

    return (
        <>
            <ListStyled>
                {siteList.map((site: Site, index: number) =>
                {
                    return (
                        <ListItem
                            onClick={() => onEnterpriseListItemClicked(site)}
                            alignItems="flex-start"
                            key={index}
                            button={true}
                        >
                            <ListItemAvatar>
                                <Avatar src={siteHelpers.generateSiteIconUrl(site)} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={siteHelpers.generateSiteName(site)}
                                secondary={siteHelpers.generateSiteUrl(site)}
                            />
                        </ListItem>);
                })}
            </ListStyled>
            <Gradient style={{ display: isSmallScreen && siteList.length > 3 ? "inline-block" : "none" }} />
        </>
    );
};

export default EnterpriseSitesList;