import React, { useContext } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import { styled } from "@mui/system";

const MailIcon = styled(MailOutlineSharpIcon)({
    "&.MuiSvgIcon-root": {
        width: 120,
        height: 120
    }
});

/**
 * Email Status section
 * @param props Props for functional component
 * @returns 
 */
const EmailStatus: React.FC = (): JSX.Element =>
{
    const context = useContext(FindMyPanoptoContext);
    const { emailSent, resetState } = context;
    const { t } = useTranslation();

    return (
        <Grid
            item
            xs={12}
        >
            <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                direction="column"
            >
                {emailSent && (
                    <Grid item>
                        <MailIcon/>
                    </Grid>
                )}
                <Grid item>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item>
                            <Typography variant="body1">
                                {t(`${(emailSent ? "Wrong email" : "Wrong sites")}?`)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link
                                sx={{
                                    verticalAlign: "baseline"
                                }}
                                underline="none"
                                variant="body1"
                                component="button"
                                onClick={resetState}
                            >
                                {t("Re-enter your email address")}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body1"
                        align="center"
                    >
                        {`${t("You can also")} `}
                        <Link href="https://support.panopto.com/">
                            {t("contact support")}
                        </Link>
                        {` ${t("or go to")} `}
                        <Link href="https://www.panopto.com">
                            {t("panopto.com.")}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmailStatus;