/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import RedirectTarget from "../Models/RedirectTarget";
import Site from "../Models/Site";
import SiteList from "../Models/SiteList";
import WizardStep from "../Models/WizardStep";
import { FindMyPanoptoContextType } from "./FindMyPanoptoContextType";

/**
 * Default context state
 */
export const findMyPanoptoContextDefaultState:FindMyPanoptoContextType = {
    emailSent: false,
    email: "",
    hideMarketingText: false,
    showFullPageLoader: false,
    setAllSitesList: (newAllSitesList: SiteList | undefined): void => {},
    setEmail: (newEmail: string): void => {},
    setEmailSent: (newEmailSent: boolean): void => {},
    setPersonalSite: (newPersonalSite?: Site): void => {},
    setRedirectTarget: (newRedirectTarget?: RedirectTarget): void => {},
    setRedirectParams: (newRedirectParams?: string): void => {},
    setDestination: (newDestination?: string): void => {},
    resetState: (): void => {},
    resetSites: (): void => {},
    setShowFullPageLoader: async (displayState: boolean): Promise<void> => {},
    wizardStep: WizardStep.Home,
    setWizardStep: (newWizardStep:WizardStep): void => {},
    emailFromQueryHandled: false,
    setEmailFromQueryHandled: (newEmailFromQueryHandled: boolean): void => {},
};

export default React.createContext<FindMyPanoptoContextType>(
    findMyPanoptoContextDefaultState,
);
