import React from "react";
import { useLocation } from "react-router-dom";
import RedirectTarget from "../Models/RedirectTarget";
import Site from "../Models/Site";
import SiteList from "../Models/SiteList";
import WizardStep from "../Models/WizardStep";
import FindMyPanoptoContext, { findMyPanoptoContextDefaultState } from "./FindMyPanoptoContext";

/**
 * Is Marketing text hidden
 * @returns true or false
 */
const isMarketingTextHidden = (redirectParams?: string): boolean =>
{
    if (!redirectParams)
    {
        return false;
    }

    const decodedParams = decodeURIComponent(redirectParams);
    return decodedParams.indexOf("mobileapp=true") > -1;
};

/**
 * Content provider
 * @param param0 Children
 * @returns functional component
 */
const FindMyPanoptoContextProvider: React.FC = ({ children }) =>
{
    const location = useLocation();

    const queryParameters = new URLSearchParams(location.search);

    const [allSitesList, setAllSitesList] = React.useState<SiteList | undefined>(undefined);

    const [email, setEmail] = React.useState<string>("");

    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [wizardStep, setWizardStep] = React.useState<WizardStep>(findMyPanoptoContextDefaultState.wizardStep);
    const [personalSite, setPersonalSite] = React.useState<Site | undefined>(undefined);

    const redirectParamsLocal = queryParameters.get("redirectParams") || undefined;
    const [redirectParams, setRedirectParams] = React.useState<string | undefined>(
        redirectParamsLocal,
    );

    const [redirectTarget, setRedirectTarget] = React.useState<RedirectTarget | undefined>(undefined);
    const [destination, setDestination] = React.useState<string | undefined>(queryParameters.get("destination") || undefined);
    const hideMarketingText = isMarketingTextHidden(redirectParamsLocal);

    const [showFullPageLoader, setShowFullPageLoaderInternal] = React.useState<boolean>(false);
    const setShowFullPageLoader = async (newState: boolean): Promise<void> =>
    {
        setShowFullPageLoaderInternal(newState);
    };

    const [emailFromQueryHandled, setEmailFromQueryHandled] = React.useState<boolean>(findMyPanoptoContextDefaultState.emailFromQueryHandled);

    /**
     *  Reset State
     */
    const resetState = (): void =>
    {
        resetSites();
        setEmail("");
        setEmailSent(findMyPanoptoContextDefaultState.emailSent);
        setWizardStep(findMyPanoptoContextDefaultState.wizardStep);
        setRedirectTarget(findMyPanoptoContextDefaultState.redirectTarget);
        setRedirectParams(undefined);
        setDestination(undefined);
    };

    /**
     * Reset sites
     */
    const resetSites = (): void =>
    {
        setAllSitesList(findMyPanoptoContextDefaultState.allSitesList);
        setPersonalSite(findMyPanoptoContextDefaultState.personalSite);
    };

    return (
        <FindMyPanoptoContext.Provider
            value={{
                allSitesList,
                setAllSitesList,

                email,
                setEmail,

                emailSent,
                setEmailSent,

                wizardStep,
                setWizardStep,

                personalSite,
                setPersonalSite,

                redirectTarget,
                setRedirectTarget,

                redirectParams,
                setRedirectParams,

                destination,
                setDestination,

                hideMarketingText,

                showFullPageLoader,
                setShowFullPageLoader,

                resetState,
                resetSites,

                emailFromQueryHandled,
                setEmailFromQueryHandled
            }}
        >
            {children}
        </FindMyPanoptoContext.Provider>
    );
};

export default FindMyPanoptoContextProvider;
