import EmailMyPanoptoService from "../Interfaces/EmailMyPanoptoService";
import ApiServicesBase from "./ApiServicesBase";

export default class EmailMyPanoptoServiceImpl extends ApiServicesBase
    implements EmailMyPanoptoService 
{
    private emailEndpoint = "emailmypanopto";

    /**
     * Email site list to email address
     * @param email Email  address
     */
    public emailSiteList = async (email: string): Promise<boolean> => 
    {
        if (email) 
        {
            try 
            {
                await this.BuildGetRequest(this.emailEndpoint, email);
                return true;
            }
            catch (error) 
            {
                console.log("Server error", error);
            }
        }

        return false;
    };
}
