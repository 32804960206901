import { styled } from "@mui/system";
import { Grid } from "@mui/material";

/**
 * Label for email field
 */
export default styled(Grid)(({ theme }) => ({
    position: "relative",
    height: 235,
    [theme.breakpoints.up("md")]: {
        height: 235,
    },
}));
