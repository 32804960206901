import Environment from "../Interfaces/Environment";

// The file contents for the current environment will overwrite these during build.
// environment specific build variables are specified in .env.<environmentname> files

/**
 * Environment variables
 */
const env:Environment = {
    /**
     * Api url
     */
    apiUrl: process.env.REACT_APP_API_URL,

    /**
     * Tag manager id for google analytics
     */
    googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,

    /**
     * ReCaptcha site key
     */
    captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
};

export default env;
