import { 
    LOGIN_DESTINATION, PANOPTO_HOME_URL, PANOPTO_LOGIN_URL, PANOPTO_ZOOM_URL, ZOOM_DESTINATION 
} from "../Models/Consts";
import Site from "../Models/Site";

export class SiteHelpers
{

    /**
     * Sanitize Site Url
     * @param siteUrl site Url
     * @returns 
     */
    public sanitizeSiteUrl = (siteUrl: string): string =>
    {
        return siteUrl.replace("http://", "").replace("https://", "");
    }

    /**
     * Generate site url
     * @param targetSite Site object
     * @returns icon url
     */
    public generateSiteIconUrl = (targetSite: Site): string =>
    {
        if (!targetSite)
        {
            return "";
        }

        if ((targetSite.BrandingSquareIconUrl.indexOf("https://") !== -1) || (targetSite.BrandingSquareIconUrl.indexOf("http://") !== -1))
        {
            return targetSite.BrandingSquareIconUrl;
        }
        else
        {
            return targetSite.SiteUrl + targetSite.BrandingSquareIconUrl;
        }
    }

    /**
     * Generate site name
     * @param targetSite Site object
     * @returns site name
     */
    public generateSiteName = (targetSite: Site): string =>
    {
        if (!targetSite)
        {
            return "";
        }

        return (targetSite.FriendlySiteName && (targetSite.FriendlySiteName.length > 0))
            ? targetSite.FriendlySiteName
            : this.sanitizeSiteUrl(targetSite.SiteUrl);
    }

    /**
     * Generate Site Url
     * @param targetSite Site object
     * @returns Site Url
     */
    public generateSiteUrl = (targetSite: Site): string =>
    {
        if (!targetSite?.FriendlySiteName || targetSite.FriendlySiteName.length === 0)
        {
            return "";
        }

        return this.sanitizeSiteUrl(targetSite.SiteUrl);
    }

    /**
     Is Personal Site
     * @param site site to validate 
     * @returns True or False
     */
    public isPersonal = (site: Site): boolean => site?.GroupId != null
        && site.GroupId.indexOf("Trial") !== -1;

    /**
     * Build redirect url
     * @param site 
     */
    public buildRedirectUrl = (redirectSite: Site, destination?: string, redirectParams?: string): string =>
    {
        let targetSiteUrl = redirectSite.SiteUrl;
        if (targetSiteUrl.indexOf("http") === -1)
        {
            targetSiteUrl = `https://${redirectSite}`;
        }

        // Check the destination query param value.
        // We allow a shorthand (for backwards compat and for pretty links)
        // But most values should specify a URL Path or nothing (to use Home as default)
        if (destination === LOGIN_DESTINATION)
        {
            targetSiteUrl += PANOPTO_LOGIN_URL;
        }
        else if (destination === ZOOM_DESTINATION)
        {
            targetSiteUrl += PANOPTO_ZOOM_URL;
        }
        else if (destination)
        {
            targetSiteUrl += decodeURIComponent(destination);
        }
        else
        {
            targetSiteUrl += PANOPTO_HOME_URL;
        }

        if (redirectParams)
        {
            targetSiteUrl += `?${redirectParams}`;
        }
        return targetSiteUrl;
    }
}