import React, { useContext } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import CustomButton from "./Shared/CustomButton";
import GridWithLimitedHeight from "./Shared/GridWithLimitedHeight";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import { SiteHelpers } from "../Utils/SiteHelpers";

/**
 * PanoptoProSection props
 */
interface PanoptoProSectionProps
{
    siteHelpers: SiteHelpers
}

/**
 * Panopto pro section
 * @param props Props for functional component
 * @returns 
 */
const PanoptoProSection:React.FC<PanoptoProSectionProps> = (props: PanoptoProSectionProps): JSX.Element =>
{
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const context = useContext(FindMyPanoptoContext);
    const { personalSite, destination, redirectParams } = context;
    if (!personalSite || isSmallScreen)
    {
        return <></>;
    }

    const { siteHelpers } = props;

    /**
    * On Panopto pro button click
    */
    const onPanoptoProButtonClick = ():void =>
    {
        if(!personalSite)
        {
            return;
        }
        window.location.href = siteHelpers.buildRedirectUrl(personalSite, destination, redirectParams);
    };

    return (
        <Grid
            item
            xs={12}
            md={6}
        >
            <GridWithLimitedHeight
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
            >
                <Grid item>
                    <CustomButton
                        size="large"
                        variant="contained"
                        onClick={onPanoptoProButtonClick}
                    >
                        Panopto Pro
                    </CustomButton>
                </Grid>
            </GridWithLimitedHeight>
        </Grid>
    );
};

export default PanoptoProSection;