import
{
    Avatar, Fade, Grid, List, ListItem, ListItemAvatar, ListItemText
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import
{
    REDIRECT_TIMER
} from "../Models/Consts";
import Site from "../Models/Site";
import { SiteHelpers } from "../Utils/SiteHelpers";
import GridWithLimitedHeight from "./Shared/GridWithLimitedHeight";

/**
 * Spinner avatar
 */
const SpinnerAvatar = styled(Avatar)({
    "&.MuiAvatar-colorDefault": {
        backgroundColor: "#ffffff"
    }
});

/**
 * Redirecting state
 */
interface RedirectingToState
{
    showAnimation: boolean,
    redirectTimeoutHandle?: NodeJS.Timeout,
}

/**
 * Redirecting props
 */
interface RedirectingToProps
{
    siteHelpers: SiteHelpers
}

/**
 * Redirect to site url
 */
class RedirectingTo extends React.Component<RedirectingToProps, RedirectingToState> 
{
    private readonly siteHelpers: SiteHelpers;
    constructor (props: RedirectingToProps)
    {
        super(props);

        this.state = {
            showAnimation: false,
        };
        this.siteHelpers = props.siteHelpers;
    }

    /**
     * Build redirect url
     * @returns 
     */
    buildUrl = (redirectSite: Site): string =>
    {
        const { destination, redirectParams } = this.context;
        return this.siteHelpers.buildRedirectUrl(redirectSite, destination, redirectParams);
    }

    componentDidMount = (): void =>
    {
        const { redirectTarget } = this.context;
        if (!redirectTarget)
        {
            return;
        }

        const { redirectTimeoutHandle } = this.state;
        if (redirectTimeoutHandle)
        {
            clearTimeout(redirectTimeoutHandle);
        }

        const url = this.buildUrl(redirectTarget.site);
        this.setState((prevState) => ({
            ...prevState,
            showAnimation: true,
            redirectTimeoutHandle: setTimeout((urlToRedirect) =>
            {
                window.location.href = urlToRedirect;
            }, REDIRECT_TIMER, url),
        }));
    }

    render = (): JSX.Element =>
    {
        const { redirectTarget } = this.context;
        const { showAnimation } = this.state;
        return (
            <Fade
                in={showAnimation}
                timeout={1800}
            >
                <Grid
                    item
                    xs={12}
                >
                    <GridWithLimitedHeight
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item>
                            <List>
                                <ListItem
                                    alignItems="flex-start"
                                >
                                    <ListItemAvatar sx={{ m: 1, position: "relative" }}>
                                        <SpinnerAvatar src={this.siteHelpers.generateSiteIconUrl(redirectTarget.site)}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={this.siteHelpers.generateSiteName(redirectTarget.site)}
                                        secondary={this.siteHelpers.generateSiteUrl(redirectTarget.site)}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </GridWithLimitedHeight>
                </Grid>
            </Fade>
        );
    }
}

RedirectingTo.contextType = FindMyPanoptoContext;

export default RedirectingTo;