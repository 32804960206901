import React from "react";
import ReactDOM from "react-dom";
import
{
    BrowserRouter as Router,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import ReactGA from "react-ga";
import Environment from "./Utils/Environment";
import theme from "./theme";
import "./i18n";
import FindMyPanoptoContextProvider from "./Context/FindMyPanoptoContextProvider";
import EmailMyPanoptoServiceImpl from "./Services/EmailMyPanoptoServiceImpl";
import FindMyPanoptoServiceImpl from "./Services/FindMyPanoptoServiceImpl";
import ValidationHelpers from "./Utils/ValidationHelpers";
import { FindMyPanoptoDefaultProps } from "./Interfaces/FindMyPanoptoDefaultProps";
import App from "./App";
import { SiteHelpers } from "./Utils/SiteHelpers";

if (Environment.googleTagManagerId && Environment.googleTagManagerId !== "")
{
    ReactGA.initialize(Environment.googleTagManagerId);
}

// App Default state
const findMyPanoptoDefaultProps: FindMyPanoptoDefaultProps = {
    services: {
        emailMyPanoptoService: new EmailMyPanoptoServiceImpl(Environment),
        findMyPanoptoService: new FindMyPanoptoServiceImpl(Environment),
    },
    utils: {
        validationHelpers: new ValidationHelpers(),
        siteHelpers: new SiteHelpers(),
    },
    environment: Environment
};

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider 
            theme={theme}
        >
            <Router>
                <CssBaseline />
                <FindMyPanoptoContextProvider>
                    <App
                        {...findMyPanoptoDefaultProps}
                    />
                </FindMyPanoptoContextProvider>
            </Router>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);
