import React, { Suspense } from "react";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import FullHeightGrid from "./Components/Shared/FullHeightGrid";
import FindMyPanoptoContext from "./Context/FindMyPanoptoContext";
import FullPageSpinner from "./Components/Shared/FullPageSpinner";
import AccountPicker from "./Components/AccountPicker";
import SearchForm from "./Components/SearchForm";
import Banner from "./Components/Banner";
import Title from "./Components/Title";
import WizardStep from "./Models/WizardStep";
import { FindMyPanoptoDefaultProps } from "./Interfaces/FindMyPanoptoDefaultProps";
import RedirectingTo from "./Components/RedirectingTo";
import EmailStatus from "./Components/EmailStatus";

class App extends React.Component<FindMyPanoptoDefaultProps, unknown> 
{
    /**
     * Get Component to render
     * @returns 
     */
    getComponentToRender = (): JSX.Element =>
    {
        const { wizardStep } = this.context;
        const { utils } = this.props;
        switch (wizardStep)
        {
            case WizardStep.RedirectToSite:
            {
                return <RedirectingTo
                    siteHelpers={utils.siteHelpers}
                       />;
            }
            case WizardStep.ChoosingAccountType:
            case WizardStep.ChoosingSite:
            {
                return <AccountPicker {...this.props} />;
            }
            case WizardStep.EmailSent:
            {
                return <EmailStatus />;
            }
            default:
            {
                return<SearchForm {...this.props} />;
            }
        }
    }

    render = (): JSX.Element =>
    {
        const { showFullPageLoader } = this.context;

        return (
            <Suspense fallback={<FullPageSpinner />}>
                <Container maxWidth="md">
                    <FullPageSpinner hidden={!showFullPageLoader} />
                    <FullHeightGrid
                        role="main"
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Banner />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <Title />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            {this.getComponentToRender()}
                        </Grid>
                    </FullHeightGrid>
                </Container>
            </Suspense>
        );
    }
}

App.contextType = FindMyPanoptoContext;
export default App;
