import Site from "./Site";

/**
 * Site list returned by Api with info do we need to send an email 
 */
export default class SiteList 
{
    constructor (public sites: Site[] = [], public sendemail = false) 
    {

    }
}
