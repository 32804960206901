import {
    Box,
} from "@mui/material";
import { styled } from "@mui/system";

/**
 * Full Width Box With Center Content
 */
export default styled(Box)(({
    display: "flex",
    justifyContent: "center",
    width: "100%",
}));
