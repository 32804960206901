import React, { useContext } from "react";
import
{
    Grid, useTheme, useMediaQuery
} from "@mui/material";
import GridWithLimitedHeight from "../Shared/GridWithLimitedHeight";
import FindMyPanoptoContext from "../../Context/FindMyPanoptoContext";
import { SiteHelpers } from "../../Utils/SiteHelpers";
import ChooseEnterpriseAccountType from "./ChooseEnterpriseAccountType";
import EnterpriseSitesList from "./EnterpriseSitesList";

/**
 * EnterpriseSection props
 */
interface EnterpriseSectionProps
{
    siteHelpers: SiteHelpers
}

/**
 * Enterprise section
 * @param props Properties used by this section
 * @returns 
 */
const EnterpriseSection: React.FC<EnterpriseSectionProps> = (props: EnterpriseSectionProps): JSX.Element =>
{
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const context = useContext(FindMyPanoptoContext);
    const {
        personalSite
    } = context;

    return (
        <Grid
            item
            xs={12}
            md={(personalSite ? 6 : 12)}
        >
            <GridWithLimitedHeight
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
            >
                <ChooseEnterpriseAccountType isSmallScreen={isSmallScreen} />
                <EnterpriseSitesList
                    isSmallScreen={isSmallScreen}
                    {...props}
                />
            </GridWithLimitedHeight>
        </Grid>
    );
};

export default EnterpriseSection;