import axios, { AxiosResponse } from "axios";
import Environment from "../Interfaces/Environment";

/**
 * Api services
 */
export default class ApiServicesBase 
{
    private emailParam = "email";

    constructor (protected environment: Environment) { }

    /**
     * Constructs the url to a GET endpoint and send request
     * @param endpoint Endpoint
     * @param email
     */
    protected BuildGetRequest<T> (endpoint: string, email: string):Promise<AxiosResponse<T>> 
    {
        return axios.get<T>(`${this.environment.apiUrl}/${endpoint}?${this.emailParam}=${encodeURIComponent(email.trim())}`);
    }
}
