import Site from "../Models/Site";
import * as EmailAddresses from "email-addresses";

/**
 * Helper class for validation
 */
export default class ValidationHelpers 
{
    /**
    * Validate email format
    * @param emailToValidate Email to validate
    * @returns Email is valid or not
    */
    public validateEmailFormat = (emailToValidate?:string):
    Promise<boolean> => new Promise((resolve) => 
    {
        try 
        {
            const parsedMailbox = EmailAddresses.parseOneAddress(emailToValidate || "");
            resolve(parsedMailbox?.type === "mailbox");
        }
        catch (e) 
        {
            console.log(e);
            resolve(false);
        }
    });

    /**
     * Validate Url Format
     * @param urlToValidate url to validate
     * @returns is valid or not
     */
    public validateUrlFormat = (urlToValidate:string):boolean => 
    {
        const pattern = new RegExp(
            "^"
              // protocol identifier
              + "(?:(?:https?|ftp)://)"
              // user:pass authentication
              + "(?:\\S+(?::\\S*)?@)?"
              + "(?:"
                // IP address exclusion
                // private & local networks
                + "(?!(?:10|127)(?:\\.\\d{1,3}){3})"
                + "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})"
                + "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})"
                // IP address dotted notation octets
                // excludes loopback network 0.0.0.0
                // excludes reserved space >= 224.0.0.0
                // excludes network & broadcast addresses
                // (first & last IP address of each class)
                + "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])"
                + "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}"
                + "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))"
              + "|"
                // host name
                + "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)"
                // domain name
                + "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*"
                // TLD identifier
                + "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))"
                // TLD may end with dot
                + "\\.?"
              + ")"
              // port number
              + "(?::\\d{2,5})?"
              // resource path
              + "(?:[/?#]\\S*)?"
            + "$", "i",
        );

        if (!pattern.test(urlToValidate)) 
        {
            return false;
        }
        return true;
    };

    /**
     * Validate Image format
     * @param imageUrl Image Url
     * @returns Valid or not valid
     */
    public validateImageFormat = (imageUrl: string): boolean => 
    {
        let isValid = false;
        let imageUrlLength: number;

        if (imageUrl) 
        {
            imageUrlLength = imageUrl.length;

            isValid = (imageUrl.indexOf(".png", imageUrlLength - 4) !== -1)
                      || (imageUrl.indexOf(".jpg", imageUrlLength - 4) !== -1)
                      || (imageUrl.indexOf(".gif", imageUrlLength - 4) !== -1)
                      || (imageUrl.indexOf(".jpeg", imageUrlLength - 5) !== -1);
        }

        return isValid;
    };

    /**
     * Validate sites
     * @param sitesToValidate Site List
     */
    public validateSites = (sitesToValidate:Site[]):Site[] => 
    {
        if (!sitesToValidate) 
        {
            return [];
        }

        return sitesToValidate.filter(
            (site:Site) => this.validateUrlFormat(site.SiteUrl),
        )
            .map((site:Site) => 
            {
                const resultSite = site;
                // Url was just validated,
                // so lets validate the icon or replace it with the default and display.
                if (!resultSite.BrandingSquareIconUrl
                    || (resultSite.BrandingSquareIconUrl.indexOf("/Panopto/Styles/Less") !== -1)
                    || !this.validateImageFormat(resultSite.BrandingSquareIconUrl)) 
                {
                    resultSite.BrandingSquareIconUrl = "https://s3.amazonaws.com/panopto-findmypanopto/PanoptoSquareIcon.png";
                }

                return resultSite;
            });
    };
}
