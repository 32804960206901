import { useContext } from "react";
import { Divider, styled } from "@mui/material";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";

/**
 * Sections Divider
 */
const StyledDivider = styled(Divider)(({ theme }) => ({
    marginRight: "-1px",
    [theme.breakpoints.down("md")]: {
        display: "none"
    },
}));

/**
 * Sections divider
 * @param props Props for functional component
 * @returns 
 */
const SectionsDivider = (): JSX.Element =>
{
    const context = useContext(FindMyPanoptoContext);
    const { personalSite } = context;
    if (!personalSite)
    {
        return <></>;
    }

    return (
        <StyledDivider
            orientation="vertical"
            flexItem
        />
    );
};

export default SectionsDivider;