import FindMyPanoptoService from "../Interfaces/FindMyPanoptoService";
import SiteList from "../Models/SiteList";
import SiteListWrapper from "../Models/SiteListWrapper";
import ApiServicesBase from "./ApiServicesBase";

export default class FindMyPanoptoServiceImpl extends ApiServicesBase
    implements FindMyPanoptoService 
{
    private searchEndpoint = "findmypanopto";

    /**
     * Search sites by email address
     * @param email Email address
     */
    public search = async (email: string):Promise<SiteList> => 
    {
        if (email) 
        {
            try 
            {
                const response = await this.BuildGetRequest<SiteListWrapper>(this.searchEndpoint,
                    email);
                return response.data.data;
            }
            catch (error) 
            {
                console.log("Server error", error);
            }
        }

        return new SiteList();
    };
}
