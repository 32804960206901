/**
 * Currently we are using wizard steps for site navigation
 * If we switch to route it will be easy to replace steps with routes
 */
enum WizardStep {
    Home = 1,
    ChoosingAccountType,
    ChoosingSite,
    EmailSent,
    RedirectToSite,
}

export default WizardStep;
