import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledFullPageBox = styled(Box)(({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    backgroundColor: "#ffffff",
    "& .MuiCircularProgress-root": {
        left: "50%",
        marginLeft: -40,
        top: "50%",
        marginTop: -40,
        position: "absolute",
    },
}));

/**
 * Spinner props
 */
interface FullPageSpinnerProps{
    hidden?:boolean | undefined
}

const FullPageSpinner:React.FC<FullPageSpinnerProps> = (props:FullPageSpinnerProps):JSX.Element => 
{
    const { hidden } = props;
    if (hidden) 
    {
        return <></>;
    }
    return (
        <StyledFullPageBox>
            <CircularProgress size={80} />
        </StyledFullPageBox>
    );
};
FullPageSpinner.defaultProps = {
    hidden: true,
};

export default FullPageSpinner;
