import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
    components:
  {
      MuiLink:
      {
          defaultProps:
        {
            underline: "none",
            color: "#0099CC",
        },
      },
  },
    palette: {
        primary: {
            main: "#30733f",
        },
        secondary: {
            main: "#19857b",
        },
        error: {
            main: "#990000",
        },
        text: {
            primary: "#464646",
        },
    },
    typography: {
        fontFamily: "Segoe UI, Helvetica, sans-serif",
        h1: {
            fontSize: 45,
            fontWeight: 200,
        },
        h2: {
            fontSize: 22,
            fontWeight: 200,
            color: "#767676",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        },
    },
});

export default theme;
