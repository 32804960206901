import React from "react";
import CustomButton from "../Shared/CustomButton";
import FindMyPanoptoContext from "../../Context/FindMyPanoptoContext";
import WizardStep from "../../Models/WizardStep";

/**
 * Choose Enterprise Account Type parameters
 */
interface ChooseEnterpriseAccountTypeProps
{
    isSmallScreen: boolean
}

/**
 * Choose enterprise account type
 * @returns 
 */
class ChooseEnterpriseAccountType extends React.Component<ChooseEnterpriseAccountTypeProps, unknown>
{
    constructor (props: ChooseEnterpriseAccountTypeProps)
    {
        super(props);      
    }

    /**
     * Is Correct Wizzard Step
     * @returns True or false
     */
    private isCorrectWizzardStep = (): boolean =>
    {
        const {
            wizardStep
        } = this.context;
        return wizardStep === WizardStep.ChoosingAccountType;
    }

    /**
     * Show enterprise site list
     * @returns
     */
    private showEnterpriseSitesList = (): void =>
    {
        const { setWizardStep } = this.context;
        setWizardStep(WizardStep.ChoosingSite);
    };

    /**
     * Should redirect to enterprise list
     * @returns redirect to enterprise list
     */
    private shouldRedirectToEnterpriseList = (): boolean =>
    {
        const { personalSite } = this.context;
        return this.props.isSmallScreen || !personalSite ? true : false;
    }

    render = (): JSX.Element =>
    {
        if (!this.isCorrectWizzardStep())
        {
            return <></>;
        }

        if(this.shouldRedirectToEnterpriseList())
        {
            this.showEnterpriseSitesList();
            return <></>;
        }

        return (
            <CustomButton
                size="large"
                variant="contained"
                onClick={this.showEnterpriseSitesList}
            >
                Panopto Enterprise
            </CustomButton>
        );
    };
}

ChooseEnterpriseAccountType.contextType = FindMyPanoptoContext;
export default ChooseEnterpriseAccountType;