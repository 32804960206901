/**
 * Panopto login destination
 */
export const LOGIN_DESTINATION = "login";

/**
 * Panopto login url
 */
export const PANOPTO_LOGIN_URL = "/Panopto/Pages/Auth/Login.aspx";

/**
 * Panopto zoom destination
 */
export const ZOOM_DESTINATION = "zoomintegration";

/**
 * Panopto zoom url
 */
export const PANOPTO_ZOOM_URL = "/Panopto/Pages/Admin/ZoomIntegration.aspx";

/**
 * Panopto home url
 */
export const PANOPTO_HOME_URL = "/Panopto/Pages/Home.aspx";

/**
 * Show ReCaptcha after this number of failed attempts 
 */
export const CAPTCHA_THRESHOLD = 5;

/**
 * Email Query parameter 
 */
export const EMAIL_QUERY_PARAMETER = "email";

/**
 * Timer used for site info animation when we have auto redirection to site
 */
export const REDIRECT_TIMER = 3000;