import React from "react";
import
{
    Link, FormHelperText, Grid, FormControl, InputLabel, Input, Box, Collapse 
} from "@mui/material";

import { withTranslation, Trans } from "react-i18next";
import { styled } from "@mui/system";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SiteList from "../Models/SiteList";
import ErrorType from "../Models/ErrorType";
import Site from "../Models/Site";
import CustomButton from "./Shared/CustomButton";
import FullWidthBoxWithCenterContent from "./Shared/FullWidthBoxWithCenterContent";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import { CAPTCHA_THRESHOLD, EMAIL_QUERY_PARAMETER } from "../Models/Consts";
import { Services } from "../Interfaces/Services";
import { Utils } from "../Interfaces/Utils";
import WizardStep from "../Models/WizardStep";
import ButtonSpinner from "./Shared/ButtonSpinner";
import Environment from "../Interfaces/Environment";
import ReCAPTCHA from "react-google-recaptcha";
import { FindMyPanoptoDefaultProps } from "../Interfaces/FindMyPanoptoDefaultProps";

/**
 * Label for email field
 */
const Label = styled(InputLabel)(({ theme }) => ({
    fontSize: 22,
    textAlign: "center",
    width: "100%",
    fontWeight: 200,
    transformOrigin: "top center",
    "&.Mui-error":
    {
        color: theme.palette.text.primary,
    },
    "&.Mui-focused":
    {
        paddingBottom: 30,
        color: theme.palette.text.primary,
    },
}));

/**
 * Email Input field
 */
const EmailInput = styled(Input)({
    padding: "7px 0 7px 0",
    "& .MuiInput-input":
    {
        fontSize: 22,
        textAlign: "center",
    },
});

/**
 * Error Message
 */
const ErrorMessageStyled = styled(FormHelperText)(({
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 25,
    color: "#990000",
}));

/**
 * Search form state
 */
interface SearchFormState
{
    attemptCount: number,
    errorType?: ErrorType,
    prevErrorType?: ErrorType,
    email: string,
    isLoading: boolean,
    ranCaptcha: boolean,
    hideErrorMessage: boolean
}

/**
 * Search form parameters
 */
interface SearchFormProps extends RouteComponentProps, FindMyPanoptoDefaultProps
{

}

/**
 * Search form component
 */
class SearchForm extends React.Component<SearchFormProps, SearchFormState> 
{
    refRecaptcha: React.RefObject<ReCAPTCHA>;

    readonly services: Services;
    readonly utils: Utils;
    readonly environment: Environment

    constructor (props: SearchFormProps)
    {
        super(props);

        const { services, utils, environment } = props;
        this.services = services;
        this.utils = utils;
        this.environment = environment;

        this.refRecaptcha = React.createRef<ReCAPTCHA>();

        const emailFromQueryParameter = this.getEmailFromQuery(props) || "";
        this.state = {
            attemptCount: 0,
            email: emailFromQueryParameter,
            isLoading: false,
            ranCaptcha: false,
            hideErrorMessage: true,
        };
    }

    componentDidMount = () =>
    {
        const { email, isLoading } = this.state;
        const { emailFromQueryHandled, setEmailFromQueryHandled, email: emailFromContext } = this.context;

        if (email && !isLoading)
        {
            if(emailFromQueryHandled)
            {
                this.setState((prevState) => ({
                    ...prevState,
                    email: emailFromContext,
                }));
            }
            else
            {
                const { setShowFullPageLoader } = this.context;
                setEmailFromQueryHandled(true);
                setShowFullPageLoader(true)
                    .then(this.search)
                    .then(async () =>
                    {
                        await setShowFullPageLoader(false);
                    });
            }
            
        }
    };

    /**
     * Get email from query params
     * @param params Component params
     * @returns Email
     */
    private getEmailFromQuery = (params: SearchFormProps): string | undefined =>
    {
        const { location } = params;
        const queryParameters = new URLSearchParams(location.search);

        // Get email from query parameter
        return queryParameters.get(EMAIL_QUERY_PARAMETER) || undefined;
    };

    /**
     * Set state 
     * @param name State property
     * @param value state value
     * @returns 
     */
    private setStateAsync = (name: string, value?: unknown): Promise<void> => new Promise((resolve) =>
    {
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }), resolve);
    });

    /**
     * Set error type 
     * @param newErrorType new error type
     * @returns 
     */
    private setErrorTypeAsync = (newErrorType?: ErrorType): Promise<void> => new Promise((resolve) =>
    {
        this.setState((prevState) => ({
            ...prevState,
            prevErrorType: prevState.errorType,
            errorType: newErrorType,
            hideErrorMessage: newErrorType === undefined
        }), resolve);
    });

    /**
     * Increment attempt count
     * @returns Promise
     */
    private incrementAttemptCount = (): Promise<void> => new Promise((resolve) =>
    {
        this.setState((prevState) => ({
            ...prevState,
            attemptCount: prevState.attemptCount + 1,
        }), resolve);
    });

    /**
     * Email Site List
     */
    private emailSiteList = async (sentToEmail: string): Promise<WizardStep | undefined> =>
    {
        const { setEmailSent } = this.context;
        setEmailSent(await this.services.emailMyPanoptoService.emailSiteList(sentToEmail));

        return WizardStep.EmailSent;
    };

    /**
     * Validate site list
     * @param siteListToValidate Site List
     */
    private validateSiteList = async (siteListToValidate: SiteList): Promise<WizardStep | undefined> =>
    {
        const {
            setAllSitesList, setRedirectTarget, setPersonalSite, resetSites
        } = this.context;

        resetSites();

        const allValidSitesSites: Site[] = this.utils.validationHelpers
            .validateSites(siteListToValidate.sites);

        const personalSite: Site | undefined = allValidSitesSites.find((site: Site) => this.utils.siteHelpers.isPersonal(site));
        const enterpriseSites: Site[] = allValidSitesSites.filter((site: Site) => !this.utils.siteHelpers.isPersonal(site));

        if (enterpriseSites.length === 0)
        {
            if (!personalSite)
            {
                await this.setErrorTypeAsync(ErrorType.NoSites);
                return;
            }

            // Redirect to personal site
            setRedirectTarget({
                directRedirect: false,
                site: personalSite
            });
            return WizardStep.RedirectToSite;
        }

        // Redirect to the the only site in the list
        if (enterpriseSites.length === 1 && !personalSite)
        {
            setRedirectTarget({
                directRedirect: false,
                site: enterpriseSites[0]
            });
            return WizardStep.RedirectToSite;
        }

        setAllSitesList(
            new SiteList(allValidSitesSites, siteListToValidate.sendemail),
        );

        if (personalSite)
        {
            setPersonalSite(personalSite);
        }

        return WizardStep.ChoosingAccountType;
    };

    /**
     * Process search list
     * @param siteListToProcess site list
     * @param searchResultsForEmail search Results for email
     * @returns next WizardStep
     */
    private processSearchResults = async (siteListToProcess: SiteList,
        searchResultsForEmail: string): Promise<WizardStep | undefined> =>
    {
        if (!siteListToProcess)
        {
            return;
        }

        let nextStep: WizardStep | undefined;
        if (siteListToProcess.sites && siteListToProcess.sites.length)
        {
            nextStep = await this.validateSiteList(siteListToProcess);
        }
        else if (siteListToProcess.sendemail)
        {
            nextStep = await this.emailSiteList(searchResultsForEmail);
        }
        else
        {
            await this.setErrorTypeAsync(ErrorType.NoSites);
        }

        const { ranCaptcha } = this.state;
        if (ranCaptcha)
        {
            this.refRecaptcha.current?.reset();
            this.setStateAsync("ranCaptcha", false);
        }

        return nextStep;
    };

    /**
     * Search based on email address
     */
    private search = async (): Promise<void> =>
    {
        const { isLoading, email, ranCaptcha } = this.state;
        if(isLoading)
        {
            return;
        }

        await this.incrementAttemptCount();
        if (!await this.utils.validationHelpers.validateEmailFormat(email))
        {
            await this.setErrorTypeAsync(ErrorType.InvalidFormat);
            if (ranCaptcha)
            {
                this.refRecaptcha.current?.reset();
                await this.setStateAsync("ranCaptcha", false);
            }
            return;
        }

        await this.setStateAsync("isLoading", true);

        // Set email in global context
        const { setEmail } = this.context;
        setEmail(email);
        const newWizardStep = await this.processSearchResults(
            await this.services.findMyPanoptoService.search(email),
            email,
        );
        
        await this.setStateAsync("isLoading", false);
        if (newWizardStep)
        {
            const { setWizardStep } = this.context;
            setWizardStep(newWizardStep);
        }
    };

    /**
     * Search with ReCaptcha
     */
    private onSearchWithReCaptcha = async () =>
    {
        const { attemptCount } = this.state;

        if (attemptCount < CAPTCHA_THRESHOLD)
        {
            await this.search();
        }
        else
        {
            await this.setStateAsync("ranCaptcha", true);
            await this.refRecaptcha.current?.executeAsync();
        }
    }

    /**
     * Email text change event
     * @param event Email change event
     */
    private onEmailInputChange = async (event: React.ChangeEvent<HTMLInputElement>)
    : Promise<void> =>
    {
        await this.setStateAsync("email", event.target.value.trim());
    };

    /**
     * Email input key press event
     * @param event Input key press event
     */
    private onEmailInputKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) =>
    {
        if (event.key === "Enter")
        {
            await this.onSearchWithReCaptcha();
            event.preventDefault();
        }
        else
        {
            await this.setErrorTypeAsync(undefined);
        }
    };

    /**
     * Render error message if any
     * @returns
     */
    getErrorMessage = () =>
    {
        let {errorType} = this.state;
        const {prevErrorType, hideErrorMessage} = this.state;
        if(hideErrorMessage)
        {
            errorType = prevErrorType;
        }

        let errorComponent = undefined;
        switch (errorType)
        {
            case ErrorType.InvalidFormat: {
                errorComponent = (
                    <ErrorMessageStyled>
                        <Trans>Please enter a valid email address</Trans>
                    </ErrorMessageStyled>
                );
                break;
            }
            case ErrorType.NoSites: {
                errorComponent = (
                <ErrorMessageStyled>
                        <Trans>No account found for this email address.</Trans>
                        <br />
                        <Trans>
                        Please contact your instructor or IT administrator for
                        information on your Panopto site.
                        </Trans>
                </ErrorMessageStyled>
                );
                break;
            }
        }

        return (
            <Collapse
                timeout={1500}
                in={!hideErrorMessage}
            >
                {errorComponent}
            </Collapse>
        );
    };

    render = () =>
    {
        const {
            email, errorType, isLoading
        } = this.state;

        const { hideMarketingText } = this.context;
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                    md={8}
                >
                    <FormControl
                        fullWidth 
                        error={errorType !== undefined}
                        margin="normal" 
                        variant="standard"
                    >
                        <Label htmlFor="email"><Trans>Enter your email address</Trans></Label>
                        <EmailInput
                            required
                            inputMode="email"
                            role="search"
                            value={email}
                            onKeyPress={this.onEmailInputKeyPress}
                            onChange={this.onEmailInputChange}
                            inputProps={{ 
                                autoCapitalize: "none" 
                            }}
                        />
                        {this.getErrorMessage()}
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <FullWidthBoxWithCenterContent>
                        {this.environment.captchaSiteKey && (
                            <ReCAPTCHA
                                ref={this.refRecaptcha}
                                size="invisible"
                                sitekey={this.environment.captchaSiteKey || ""}
                                onChange={async () => 
                                {
                                await this.search();
                                }}
                            />
                        )}
                        <CustomButton
                            size="large"
                            disabled={isLoading}
                            onClick={this.onSearchWithReCaptcha}
                            variant="contained"
                        >
                            {!isLoading && (
                                <Trans>Next</Trans>
                            )}
                            {isLoading && (<ButtonSpinner size={20} />)}
                        </CustomButton>
                    </FullWidthBoxWithCenterContent>
                </Grid>
                <Grid
                    item
                    xs={12}
                    hidden={hideMarketingText}
                >
                    <Box>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                        >
                            <Grid item>
                                <Trans>New to Panopto?</Trans>
                            </Grid>
                            <Grid item>
                                <Link href="https://www.panopto.com/">
                                    <Trans>Visit our website to learn more.</Trans>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        );
    };
}
SearchForm.contextType = FindMyPanoptoContext;
export default withRouter(withTranslation()(SearchForm));
