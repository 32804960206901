import React, { useContext } from "react";
import { styled } from "@mui/system";
import { Typography, Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import FindMyPanoptoContext from "../Context/FindMyPanoptoContext";
import WizardStep from "../Models/WizardStep";

const HeaderWrapperBox = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: 56,
    margin: "25px 0",
    [theme.breakpoints.down("md")]: {
        margin: "25px 0 0 0",
    },
    textAlign: "center",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        fontSize: 32
    }
}));

const SubHeaderWrapperBox = styled(Box)({
    position: "relative",
    width: "100%",
    marginBottom: 25,
    textAlign: "center",
});

/**
 * Build Header section
 * @returns Element
 */
const Header:React.FC = (): JSX.Element => 
{
    const {
        wizardStep, email
    } = useContext(FindMyPanoptoContext);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    // const classes = useStyles();
    const { t } = useTranslation();

    /**
     * Get Title to show
     * @returns Title to show
     */
    const Title = (): JSX.Element =>
    {

        const titleToShow = ():string =>
        {
            switch(wizardStep)
            {
                case WizardStep.ChoosingAccountType:{
                    return "Please choose an account";
                }
                case WizardStep.ChoosingSite:{
                    return "Please choose a site";
                }
                case WizardStep.EmailSent:{
                    return "Check your email";
                }
                case WizardStep.RedirectToSite:{
                    return "Taking you to your site";
                }
                default:
                    return "Sign in to Panopto";
            }
        };

        return (
            <HeaderWrapperBox>
                <TitleTypography variant="h1">
                    {t(titleToShow())}
                </TitleTypography>
            </HeaderWrapperBox>
        );
    };

    /**
     * Build Subtitle
     * @returns Subtitle element
     */
    const SubTitle = (): JSX.Element =>
    {
        if(isSmallScreen 
            && (wizardStep === WizardStep.ChoosingAccountType
            || wizardStep === WizardStep.ChoosingSite))
        {
            return <></>;
        }

        let subTitleContent = undefined;
        switch(wizardStep)
        {
            case WizardStep.ChoosingAccountType:
            case WizardStep.ChoosingSite: {
                subTitleContent = (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                {t("There are multiple accounts associated with {{email}}.", { email: email || "" })}
                                <br/>
                                {t("Which one do you want to sign in to?")}
                            </Grid>
                        </Grid>
                );
                break;
            }
            case WizardStep.EmailSent:{
                subTitleContent = t("We've emailed {{email}} with a list of associated sites.",{ email: email || "" });
                break;
            }
        }

        if(!subTitleContent)
        {
            return <></>;
        }
        
        return (
            <SubHeaderWrapperBox>
                <Typography
                    variant="h2"
                    component="h2"
                >
                    {subTitleContent}
                </Typography>
            </SubHeaderWrapperBox>
        );
    };

    return (
        <>
            <Title/>
            <SubTitle/>
        </>
    );
};

export default Header;
